import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'gatsby'
import axios from 'axios';
import random from '../utils/random';
import { Context as UserContext } from './UserContext';
import { Context as LeaveContext } from './LeaveContext';
import { ActiveLinkStyle } from './layout'

export default function StartLeaveRequestLink(props) {
  const [Message, setMessage] = useState('Loading...')
  const {
    fetchData,
    Member: leaveRequestAvailable,
    setIsCovid,
    clearCovidInfo,
  } = useContext(LeaveContext);
  const { forceChangePassword } = useContext(UserContext);

  const {
    style,
    children,
    activateLink,
    isCovid,
  } = props

  useEffect(() => {
    if (!leaveRequestAvailable) {
      // API call to datasource for user data.
      const LeaveRequestURL = 'https://apps.cbcins.com/api/Service/LeaveRequest/Add'
      // Example of using a variable inside of the HTML file to use inside of a react application
      // if(window.LeaveRequestURL)
      //   LeaveRequestURL = window.LeaveRequestURL
      axios
        .get(`${LeaveRequestURL}?V=${random()}`)
        .then((res) => {
          // store the user data into the leaveRequest Store
          fetchData(res.data)
        })
        .catch((error) => {
          if (error.response.data.Message) {
            try {
              const Message = JSON.parse(error.response.data.Message)
              if (Message.IsForcePasswordChange) {
                forceChangePassword(true)
              }
            } catch (e) {
              setMessage('Error')
            }
          } else {
            setMessage('Error')
          }
        });
    }
  }, [fetchData, forceChangePassword, leaveRequestAvailable])

  const newStyle = {
    ...style,
    ...(activateLink ? ActiveLinkStyle : {}),
    ...(leaveRequestAvailable ? {} : { pointerEvents: 'none' }),
  }
  return (
    <Link
      activeStyle={ActiveLinkStyle}
      to={isCovid ? '/New_Leave_Request_Covid/' : '/New_Leave_Request_step1/'}
      style={newStyle}
      onClick={() => {
        setIsCovid(isCovid)
        clearCovidInfo()
      }}
    >
      {leaveRequestAvailable ? children : Message || 'Error' }
    </Link>
  )
}
