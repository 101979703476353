import React, { useContext } from 'react'
import { Context as LeaveContext } from './LeaveContext'

export default function Footer() {
  const { Member } = useContext(LeaveContext)

  const isMidwest = Member && Member.EmployeeClassID && Member.EmployeeClassID === 4248

  return (
    <>
      <div className={isMidwest ? 'footerWrapper midwest' : 'footerWrapper'}>
        <div className="footerHomeContent">
          <div className="footerLeftDiv">
            Copyright © 2018 CBC. All Rights Reserved.{' '}
            <span className="lightRed">|</span>{' '}
            <a href="https://www.cbcins.com/Privacypolicy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
            <br />
          </div>

          <div className="footerRightDiv">
            <a target="_blank" rel="noopener noreferrer" href={isMidwest ? 'mailto:midwestleave@controlsourceinc.com' : 'mailto:SmileBrandsLeave@ControlSourceInc.com'}>
              <div className="EmailFooterBTN">24-Hour Response M-F</div>
            </a>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a href="#">
              <div className="ChatFooterBTN">Real Time M/F 8am-5pm (PST)</div>
            </a>
            <a href={isMidwest ? 'tel:8667321557' : 'tel:8885416342'}>
              {isMidwest ? (
                <div className="PhoneFooterBTN">
                  Phone: (866) 732-1557 <br />
                  Fax: 866-505-2972<br />
                  M/F 8am-5pm (PST)
                </div>
              ) : (
                <div className="PhoneFooterBTN">
                  Phone: (888) 541-6342 <br />
                  M/F 6am-5pm (PST)<br />
                  Fax: 866-505-2972
                </div>
              )}
            </a>
          </div>
          <div className="footerLogo" />
          <p className="clear">&nbsp;</p>
          <p className="clear">&nbsp;</p>
          <p className="clear">&nbsp;</p>
          <p className="clear">&nbsp;</p>
          <p className="clear">&nbsp;</p>
          <p className="clear">&nbsp;</p>
          <p className="clear">&nbsp;</p>
          <p className="clear">&nbsp;</p>
          <p className="clear">&nbsp;</p>
          <p className="clear">&nbsp;</p>
        </div>
      </div>
    </>
  );
}
